import router from '../../router/index'
const jwt = require("jsonwebtoken");
const axios = require("axios");
require('dotenv').config({path:__dirname + '/.env'})


// A state
const state = {
  token: localStorage.getItem('token') || '',
  secret: localStorage.getItem('secret') || '',
  name: localStorage.getItem('name') || "",
  persons: localStorage.getItem('persons') || {},
  availableaccomo: localStorage.getItem('availableaccomo') || [],
  accomodations: localStorage.getItem('accomodations') || [],
  email: localStorage.getItem('email') || "",
  currentState: localStorage.getItem('currentState') || "",
};

// Get state
const getters = {
  getIRToken: (state) => state.token,
  getIRName: (state) => state.name,
  getIRPersons: (state) => state.persons,
  getEmail: (state) => state.email,
  getAvailableAccomo: (state) => state.availableaccomo,
  getAccomo: (state) => state.accomodations,
  getCurrentState: (state) => state.currentState,
};

// Action calls mutation that changes state
const actions = {
  async login({ commit }, user) {
    const headers = {
      "Content-Type": "application/json",
    };

    await axios
      .post(
        `https://bsaoc.be:8443/IR/login`,
        {
          email: user.email,
          password: user.password,
        },
        { headers: headers }
      )
      .then(function (response) {
        localStorage.setItem('secret', response.data.responsecode)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('email', user.email)
        localStorage.setItem('name', response.data.name)
        commit("loggedIn", response.data);
        router.push("/IRDashboard")
      })
      .catch(function (error) {
        state.currentState = error.response.data.message
        localStorage.setItem('currentState', error.response.data.message)
        console.log(error);
      });
  },
  async checkstate({ commit }, user) {
    console.log("checking state")
    const headers = {
      "Content-Type": "application/json",
      "Authorization":  state.token,
      "checkcode": state.secret
    };
    await axios
      .post(
        `https://bsaoc.be:8443/IR/getpersons`,
        {
          email: user.email,
        },
        { headers: headers }
      )
      .then(function (response) {
        let object = []
        response.data.forEach(person => {
          person.adult = (person.adult ==1);
          person.tiny = (person.tiny ==1);
          person.vegan = (person.vegan ==1);
          person.driver = (person.driver ==1);
          person.dayone = (person.dayone ==1);
          person.daytwo = (person.daytwo ==1);
          person.daythree = (person.daythree ==1);
          person.dayfour_one = (person.dayfour_one ==1);
          person.dayfour_two = (person.dayfour_two ==1);
          person.dayfour_three = (person.dayfour_three ==1);
          person.dayfive = (person.dayfive ==1);
          person.daysix = (person.daysix ==1);
          person.dayseven = (person.dayseven ==1);
          person.bfall = (person.bfall ==1);
          person.bfone = (person.bfone ==1);
          person.bftwo = (person.bftwo ==1);
          person.bfthree = (person.bfthree ==1);
          person.bffour = (person.bffour ==1);
          person.bffive = (person.bffive ==1);
          person.bfsix = (person.bfsix ==1);
          person.bfseven = (person.bfseven ==1);
          person.bfeight = (person.bfeight ==1);
          object.push(JSON.stringify(person))
        });
        localStorage.setItem('persons', object )
        commit("setPersons", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async addPerson({ commit }, user) {
    console.log(user)
    commit("empty")
    const headers = {
      "Content-Type": "application/json",
      "Authorization":  state.token,
      "checkcode": state.secret
    };
        await axios
      .post(
        `https://bsaoc.be:8443/IR/addPerson`,
        {
          data: user,
        },
        { headers: headers }
      )
      .then(function () {
        commit("empty")
        router.go(0);
      })
      .catch(function (error) {
        console.log(error);
      });
      
  },

  async editPerson({ commit }, user) {
    console.log(user)
    commit("empty")
    const headers = {
      "Content-Type": "application/json",
      "Authorization":  state.token,
      "checkcode": state.secret
    };
    await axios
    .post(
      `https://bsaoc.be:8443/IR/editPerson`,
      {
        data: user,
      },
      { headers: headers }
    )
    .then(function () {
      commit("empty")
      router.go(0)
    })
    .catch(function (error) {
      console.log(error);
    });
  },
    
  async deletePerson({commit }, person) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization":  state.token,
      "checkcode": state.secret
    };

    await axios
      .delete(
        `https://bsaoc.be:8443/IR/deletePerson/${person.id}`,
        { headers: headers }
      )
      .then(function () {
        router.go(0)
      commit("empty")
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async updatePerson({commit }, person) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization":  state.token,
      "checkcode": state.secret
    };

    await axios
      .put(
        `https://bsaoc.be:8443/IR/updatePerson/${person.id}`,
        {
          data: person,
        },
        { headers: headers }
      )
      .then(function () {
      
      commit("empty")
      router.go(0)
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async checkavailableaccomodations({ commit }) {
    console.log("checking available accomodations")
    const headers = {
      "Content-Type": "application/json",
      "Authorization":  state.token,
      "checkcode": state.secret
    };
    const headerstwo = {
      "Content-Type": "application/json",
      "Authorization":  state.token,
      "checkcode": state.secret,
      "email": state.email
    };
    await axios
      .get(
        `https://bsaoc.be:8443/IR/getavailableacomo`,
        { headers: headers }
      )
      .then(function (response) {
        localStorage.setItem('availableaccomo', response.data )
        commit("setAccomos",response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios
      .get(
        `https://bsaoc.be:8443/IR/getacomo`,
        { headers: headerstwo }
      )
      .then(function (response) {
        localStorage.setItem('setownAccomos', response.data )
        commit("setownAccomos",response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async addAccomodation({ commit },accomodation) {
    let email = state.email;
    let occupants = "";
    accomodation.occupants.forEach(occupant => {
      occupants += occupant.firstname + " " + occupant.lastname + ","
    });
    accomodation.occupants = occupants
    commit("empty")
    const headers = {
      "Content-Type": "application/json",
      "Authorization":  state.token,
      "checkcode": state.secret
    };
    await axios
      .post(
        `https://bsaoc.be:8443/IR/addAccomodation`,
        {
          data: accomodation,
          email: email
        },
        { headers: headers }
      )
      .then(function () {
        commit("empty")
        router.go(0)
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async deleteAcomo({commit }, person) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization":  state.token,
      "checkcode": state.secret
    };

    await axios
      .delete(
        `https://bsaoc.be:8443/IR/deleteAcomo/${person.id}`,
        { headers: headers }
      )
      .then(function () {
        router.go(0)
      commit("empty")
      })
      .catch(function (error) {
        console.log(error);
      });
  }
};
// Changes state
const mutations = {
  loggedIn: (state, data) => {
    jwt.verify(
      data.token,
      data.responsecode,
      function (err, decoded) {
        if (decoded != undefined) {
          state.name = decoded.name;
          state.token = data.token;
          state.secret = decoded.secret;
          router.push("/IRDashboard")
        }
        if(err){
          console.log(err)
        }
      })   
  },
  setPersons: (state, data) => {
    state.persons = data   
  },
  setAccomos: (state, data) => {
    state.availableaccomo = data   
  },
  setownAccomos: (state, data) => {
    state.accomodations = data
  },
  removeAuth: (state) =>{
    state.secret ="";
    state.token = "";
    state.email ="";
    state.name = "";
    localStorage.setItem('secret',  "")
    localStorage.setItem('token',  "")
    localStorage.setItem('email',  "")
    localStorage.setItem('name',  "")
    router.push("/")
  },
  empty:(state) => {
    let tussen = state.token
    state.token = tussen
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
