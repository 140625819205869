<template>
  <v-dialog v-model="show" width="unset" overflow="hidden">
    <div class="background">
      <v-container fill-height fluid>
        <v-row justify="space-around" style="margin-top: -3%">
          <v-col justify-self="center">
            <v-card
              elevation="5"
              class="pa-3 mx-5"
              justify-self="center"
              style="
                max-width: 50vh;
                min-width: 30vh;
                max-height: 80vh;
                min-height: 32vh;
              "
            >
              <v-card-title
                ><v-icon>help</v-icon>International rally<v-icon
                  >help</v-icon
                ></v-card-title
              >
              <v-card-text>
                <p style="font-size: 1em">
                  Rally program
                  <a href="https://1drv.ms/w/s!Am2F2J2PH4dTgadvqPTQJ5sLTe6i1w?e=fNI6N0">here</a>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>