<template>
 <div class="background">
  <v-container fill-height fluid>
    <v-row justify="space-around" style="margin-top: -3%;">
      <v-col md="6" justify-self="center">
        <v-card elevation="5" class="pa-1">
          <v-card-title class="px-10">Login</v-card-title>      
            <v-form @submit.prevent="btnClick" v-model="valid">
            <v-container>
              <v-row justify="center">
                <v-col cols="11">
                  <v-text-field
                    :type="'email'"
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    append-icon="mail"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="11">
                  <v-text-field
                    :type="'password'"
                    v-model="password"
                    :rules="passwordRules"
                    label="Wachtwoord"
                    append-icon="lock"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
              <v-snackbar
                v-model="snackbar"
                color="primary"
              >
                {{ text }}
          
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="secondary"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
            </v-snackbar>
              </v-row>
              <v-card-actions>
                <v-row justify="space-around">
                  <v-btn
                    class="px-10 mt-8 primary"
                    type="submit"
                    depressed
                    rounded
                    >Login</v-btn
                  >
                </v-row>
              </v-card-actions>
            </v-container>
          </v-form>
      </v-card>
    </v-col>
  </v-row>
</v-container>  
</div>
</template>

<style lang="scss" scoped>
.background {
  height: 100%;
  width: 100%;
  background-image: url(../assets/background.jpg);
  background-size: cover;

}
</style>


<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import { loginService } from "@/services/auth.js";
import { mapActions } from "vuex";
import store from "../store/index";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      valid: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail cannot be empty",
        (v) => /.+@.+/.test(v) || "E-mail adress is not an e-mail adress",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
      ],
      snackbar: false,
      text: `Hello, I'm a snackbar`,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async btnClick() {
      //console.log(this.email);
      //console.log(this.password);
      
      await this.login({ email: this.email, password: this.password });
      console.log(store.getters.getCurrentState);
      this.text = store.getters.getCurrentState
      this.snackbar = true;
      //set store state to empty string
      // store.commit("currentState", "");

    },
  },
};
</script>