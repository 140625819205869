<template>
  <v-app>
    <Icon/>
    <MenuTray/>
    <HomePage/>
 </v-app>
</template>


<script>
  import Icon from '../components/Icon';
  import HomePage from '../components/HomePage';
  import MenuTray from '../components/MenuTray';
  export default {
    name: 'Home',

    components: {
      HomePage,
      MenuTray,
      Icon,
    },

    data: () => ({
    //
  }),
  };
</script>
