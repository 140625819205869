<template>
 <div class="background">
    <div class="scroll">
  <!-- <v-container fill-height fluid > -->
    <v-row style="margin-top: 0%; margin-left:10%;" class="hidden-md-and-down" >
      <v-col md="4" style="max-width: 60%;">
      <v-card elevation="5" class="pa-2 ml-10">
        <v-list dense>
          <v-subheader>Blogs and Forums</v-subheader>
          <v-list-item 
            style="min-height: 20px; max-height:20px"
            v-for="link in blfl"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
          <v-subheader>Parts</v-subheader>
          <v-list-item
            style="min-height: 20px; max-height:20px"
            v-for="link in parts"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
          <v-subheader>Clubs</v-subheader>
          <v-list-item
            style="min-height: 20px; max-height:20px"
            v-for="link in clubs"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
          <v-subheader>Other</v-subheader>
          <v-list-item
            style="min-height: 20px; max-height:20px"
            v-for="link in other"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
          <v-subheader>Oldtimercross</v-subheader>
          <v-list-item
            style="min-height: 20px; max-height:20px"
            v-for="link in oldtimercross"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
        </v-list>
      </v-card>

     </v-col>
    <!-- </v-row>  -->
  
    <!--  <v-row style="margin-top: 0%; margin-left:10%;"> -->
      <v-col md="4" style="max-width: 60%;"> 
      <v-card elevation="5" class="pa-2 ml-5">
        <v-list dense>
        <v-subheader>Manuals</v-subheader>
          <v-list-item
            style="min-height: 20px; max-height:20px"
            v-for="link in manuals"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
        </v-list>
      </v-card>
      </v-col>
    </v-row>
      <v-row style="margin-top: 0%; margin-left:1%;" class="hidden-lg-and-up" >
      <v-col md="12" style="max-width: 90%;">
      <v-card elevation="5" class="pa-2 ml-5">
        <v-list dense>
          <v-subheader>Blogs and Forums</v-subheader>
          <v-list-item 
            style="min-height: 20px; max-height:20px"
            v-for="link in blfl"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
          <v-subheader>Parts</v-subheader>
          <v-list-item
            style="min-height: 20px; max-height:20px"
            v-for="link in parts"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
          <v-subheader>Clubs</v-subheader>
          <v-list-item
            style="min-height: 20px; max-height:20px"
            v-for="link in clubs"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
          <v-subheader>Other</v-subheader>
          <v-list-item
            style="min-height: 20px; max-height:20px"
            v-for="link in other"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
          <v-subheader>Oldtimercross</v-subheader>
          <v-list-item
            style="min-height: 20px; max-height:20px"
            v-for="link in oldtimercross"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
        </v-list>
      </v-card>

     </v-col>
    <!-- </v-row>  -->
  
    <!--  <v-row style="margin-top: 0%; margin-left:10%;"> -->
      <v-col md="12" style="max-width: 90%;"> 
      <v-card elevation="5" class="pa-2 ml-5">
        <v-list dense>
        <v-subheader>Manuals</v-subheader>
          <v-list-item
            style="min-height: 20px; max-height:20px"
            v-for="link in manuals"
            :key="link.title"
          >
                <a :href="link.link" target="_new" style="font-size: 1em"> {{link.title}} </a>
          </v-list-item>
        </v-list>
      </v-card>
      </v-col>
    </v-row>
  <!-- </v-container> -->

</div>
</div>
</template>

<style lang="scss" scoped>
.background {
  height: 100%;
  width: 100%;
  background-image: url(../assets/background.jpg);
  background-size: cover;

}
.scroll {
  overflow-y: scroll;
  max-height: 93vh;
  overflow-x: hidden;

}
</style>

<script>

  export default {
data() {
    return {
      blfl: [
        { title: "B50 Org", link: "http://www.b50.org/"},
        { title: "Oude Crossmotoren", link: "http://blog.seniorennet.be/oudecrossmotoren3/"},
        { title: "Britbike", link: "http://www.britbike.com/"},
        { title: "Jos Nuyts Gillet", link: "http://josgillet.wix.com/brommersnuyts"},
        { title: "Theo Gielen: Wereldreis", link: "http://users.belgacom.net/bsa/"}

      ],
      parts: [
        { title: "Degroot BSA", link: "http://www.degroot-bsa.com/"},
        { title: "F&F onderdelen", link: "http://www.ffmotoronderdelen.be/"},
        { title: "British spares", link: "https://www.britishspares.com/"},
        { title: "BSA unit singles", link: "https://www.bsaunitsingles.com/"},
        { title: "jvmotors", link: "http://www.jvmotors.nl/"},
        { title: "Frank Jordens Spares service", link: "http://www.sparesservice.be/"},
        { title: "PVL Ignition", link: "http://www.rexcauntracing.com/"},
        { title: "HPI Electronische ontsteking", link: "http://www.hpi.be/"},
        { title: "Banden Tirendo", link: "http://www.tirendo.be/"}
      ],
      clubs: [
        { title: "BSA Owners club UK", link: "http://www.bsaownersclub.co.uk/"},
        { title: "Bantam Club", link: "https://www.bsabantamclub.com/"},
        { title: "Norton Sidecar Racing Team", link: "http://users.telenet.be/nscrt/"},
        { title: "De Oude Garde", link: "http://www.deoudegarde.be/"},
        { title: "Limburgse OMA club", link: "http://www.lomc.be/"}
      ],
      oldtimercross: [
        { title: "MX Vintage", link: "https://www.mxvintage.be/"},
        { title: "Pre65 UK", link: "https://www.pre65.co.uk/"}
      ],
      other: [
        { title: "BSA B40", link: "http://bsawdb40.com/"},
        { title: "BSA Manuals", link: "https://bsamanuals.com/"}
      ],
      manuals: [
        { title: "B44 Weslake Head", link:"B44 Weslake Head"},
        { title: "The Amal Company", link:"http://www.amalcarb.co.uk/"},
        { title: "C15 Technical", link:"http://www.bsa-c15.org.uk/"},
        { title: "B50 Technical", link:"http://www.b50.org"},
        { title: "BSA Technical", link:"http://www.motos-anglaises.com/"},
        { title: "BSA A65 front brake", link:"fotos/BSA%20A65T%201971%20caferacer%20voorrem.pdf"},
        { title: "BSA Owner & Repair", link:"http://www.bsaunitsingles.com/owners_repair_manuals.htm"},
      ],
    };
  },
    methods: {
    },
  };
</script>