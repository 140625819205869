<template>
 <div class="background">
   <div class="scroll">
  <v-container fill-height fluid>
    <v-row justify="space-around" style="margin-top: 0%;">
      <v-col md="6" justify-self="center">
        <v-card elevation="5" class="pa-1 hidden-md-and-down">
          <v-card-title>Register</v-card-title>
          <v-form class="mx-4 my-4" ref="form" lazy-validation>
            <v-card class="pa-1">
              <v-card-title>Info about you</v-card-title>
              <v-card-subtitle class="pb-0">Please fill in all info:</v-card-subtitle>

              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                    label="First Name"
                    :rules="Emptycheck"
                    v-model="FirstName"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                    label="Last Name"
                    :rules="Emptycheck"
                    v-model="LastName"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                    label="Email address"
                    :rules="Emailcheck"
                    v-model="Email"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                    label="Phone number"
                    :rules="Emptycheck"
                    v-model="Phone"
                    required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md3>
                    <v-text-field
                    label="Street Name"
                    :rules="Emptycheck"
                    v-model="StreetName"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col md1>
                    <v-text-field
                    label="House number"
                    :rules="Emptycheck"
                    v-model="Housenumber"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col md2>
                    <v-text-field
                    label="City"
                    :rules="Emptycheck"
                    v-model="City"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col md1>
                    <v-text-field
                    label="Zipcode"
                    :rules="Emptycheck"
                    v-model="Zipcode"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col md2>
                    <v-text-field
                    label="Country"
                    :rules="Emptycheck"
                    v-model="Country"
                    required
                    ></v-text-field>
                  </v-col>
                </v-row>

              </v-container>

            </v-card>
            <v-card class="pa-1">
              <v-card-title>Info about your motorcycle</v-card-title>
              <v-card-subtitle class="pb-0"
              >Please fill in all info:</v-card-subtitle
              >
              
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                    label="Brand"
                    :rules="Emptycheck"
                    v-model="Brand"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                    label="Type"
                    :rules="Emptycheck"
                    v-model="Type"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                    label="Construction year"
                    :rules="Emptycheck"
                    v-model="Constr"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                    label="Extra"
                    v-model="Extra"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md2>
                    <v-radio-group v-model="DigorPap" label="Club magazine" required>
                      <v-radio style="display:inline; margin-right:10px;"
                      :label="`Digital`"
                      :value="0"
                      ></v-radio>
                      <v-radio style="display:inline;"
                      :label="`Paper`"
                      :value="1"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col m2>
                    <v-row>
                      <v-col md2 cols="1">                      
                      <v-checkbox
                      v-model="checkbox"
                      :rules="Agreed"
                      required
                      ></v-checkbox>
                      </v-col>
                      <v-col md2 cols="11">
                      <p>I have read and agree to all <a target="_blank" rel="noopener noreferrer" href="TermsAndCond">Terms and Conditions</a></p>
                      </v-col>
                    </v-row>
                    <v-row>
                     <v-btn color="secondary" elevation="7" raised class="primary--text" @click="Register"
                     ><span>Register</span>
                   </v-btn>
                 </v-row>
               </v-col>
             </v-row>
              <p class="my-5 mx-15">
                Dont forget! There is an annual membership fee to recieve the club magazine and to be a member of the Belgian Historic Vehicle Association(formerly BFOV). Wich allows you to get an inscurance plan at a reduced cost. The annual fee is 25 euro's to be transfered to the account: BE53 7805 8256 6653 with your full name and email as reference.
              </p>
           </v-container>

         </v-card>
       </v-form>
     </v-card>
      
     <v-card elevation="5" class="pa-3 hidden-lg-and-up">
        <v-card-title>Register</v-card-title>
          <v-form class="mx-2 my-2" ref="form" lazy-validation>
            <v-card class="pa-1">
              <v-card-title>Info about you</v-card-title>
              <v-card-subtitle class="pb-0">Please fill in all info:</v-card-subtitle>

              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                    label="First Name"
                    :rules="Emptycheck"
                    v-model="FirstName"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                    label="Last Name"
                    :rules="Emptycheck"
                    v-model="LastName"
                    required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                    label="Email address"
                    :rules="Emailcheck"
                    v-model="Email"
                    required
                    ></v-text-field>
                  </v-col>
                 </v-row>
                  <v-row>
                  <v-col>
                    <v-text-field
                    label="Phone number"
                    :rules="Emptycheck"
                    v-model="Phone"
                    required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                    label="Street Name"
                    :rules="Emptycheck"
                    v-model="StreetName"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                    label="Number"
                    :rules="Emptycheck"
                    v-model="Housenumber"
                    required
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col md2>
                    <v-text-field
                    label="City"
                    :rules="Emptycheck"
                    v-model="City"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col md1>
                    <v-text-field
                    label="Zipcode"
                    :rules="Emptycheck"
                    v-model="Zipcode"
                    required
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col md2>
                    <v-text-field
                    label="Country"
                    :rules="Emptycheck"
                    v-model="Country"
                    required
                    ></v-text-field>
                  </v-col>
                </v-row>

              </v-container>

            </v-card>
            <v-card class="pa-1">
              <v-card-title>Info about your motorcycle</v-card-title>
              <v-card-subtitle class="pb-0"
              >Please fill in all info:</v-card-subtitle
              >
              
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                    label="Brand"
                    :rules="Emptycheck"
                    v-model="Brand"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                    label="Type"
                    :rules="Emptycheck"
                    v-model="Type"
                    required
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-text-field
                    label="Construction year"
                    :rules="Emptycheck"
                    v-model="Constr"
                    required
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-text-field
                    label="Extra"
                    v-model="Extra"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md2>
                    <v-radio-group v-model="DigorPap" label="Club magazine" required>
                      <v-radio style="display:inline; margin-right:10px;"
                      :label="`Digital`"
                      :value="0"
                      ></v-radio>
                      <v-radio style="display:inline;"
                      :label="`Paper`"
                      :value="1"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col m2>
                    <v-row>
                      <v-col md2 cols="2">                      
                      <v-checkbox
                      v-model="checkbox"
                      :rules="Agreed"
                      required
                      ></v-checkbox>
                      </v-col>
                      <v-col md2 cols="10">
                      <p>I have read and agree to all <a target="_blank" rel="noopener noreferrer" href="TermsAndCond">Terms and Conditions</a></p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                      </v-col>
                      <v-col cols="2">
                        <v-btn color="secondary" elevation="7" raised class="primary--text" @click="Register"
                        ><span>Register</span>
                      </v-btn>
                     </v-col>
                      <v-col cols="5">
                        <v-spacer></v-spacer>
                      </v-col>
                 </v-row>
               </v-col>
             </v-row>
              <p class="my-1 mx-1">
                Dont forget! There is an annual membership fee to recieve the club magazine and to be a member of the Belgian Historic Vehicle Association(formerly BFOV). Wich allows you to get an inscurance plan at a reduced cost. The annual fee is 25 euro's to be transfered to the account: BE53 7805 8256 6653 with your full name and email as reference.
              </p>
           </v-container>

         </v-card>
       </v-form>
     </v-card>
   
   </v-col>
 </v-row>
</v-container> 
</div> 
</div>
</template>

<style lang="scss" scoped>
.scroll {
    display:block;
    height: 100%;
    width: 100%;
    
    display:fixed;
}
.background{
  max-width: 100%;
  overflow-y: scroll;
  overflow-x:hidden;
  background-image: url(../assets/background.jpg);
  background-size: cover;
}
</style>

<script>
 const axios = require('axios');


 export default {
  data() {
    return {
      FirstName:'',
      LastName:'',
      Email:'',
      Phone:'',
      StreetName:'',
      Housenumber:'',
      City:'',
      Country:'',
      Brand:'',
      Type:'',
      Constr:'',
      Extra:'',
      DigorPap:'',
      Zipcode:'',
      checkbox:'',
      Emptycheck: [
      v => !!v || 'This field is required',
      ],
      Agreed: [
      v => !!v || 'You must agree in order to register',
      ],
      Emailcheck: [
      v => !!v || 'E-mail is required',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
    };
  },
  methods: {
    Register: function () {
     this.$refs.form.validate();
     //console.log(this.BoxID)
     var postData = {
      FirstName:this.FirstName,
      LastName:this.LastName,
      Email:this.Email,
      Phone:this.Phone,
      StreetName:this.StreetName,
      Housenumber:this.Housenumber,
      City:this.City,
      Country:this.Country,
      Brand:this.Brand,
      Type:this.Type,
      Constr:this.Constr,
      Extra:this.Extra,
      DigorPap:this.DigorPap,
      Zipcode:this.Zipcode,
    };

    let axiosConfig = {
      headers: {

      },
    };
    const url = `https://bsaoc.be:8443/Register/Add`;
    axios
    .post(url, postData , axiosConfig)
    .then((/* res */) => {
          //Users terug callen om de data te updaten
          this.$router.push("/Success")
        })
    .catch((err) => {
      console.log(err);
    });
  },
},
};
</script>