<template>
  <v-app>
      <Icon/>
      <MenuTray/>
      <ContactComp/>
  </v-app>
</template>
<style>
  html { overflow-y: hidden }
</style>

<script>
import Icon from '../components/Icon';
import ContactComp from '../components/ContactComp';
import MenuTray from '../components/MenuTray';
export default {
  name: 'Photos',

  components: {
    ContactComp,
    MenuTray,
    Icon,
  },

  data: () => ({
    //
  }),
};
</script>
