<template>
 <div class="background">
     <div class="scroll pl-0 py-5">
            <v-row>
                <v-col style="max-width:60vh; min-width:27vh;"
                v-for="Album in Albums"
                :key="Album.Name"
                class="mx-3"
                >
                    <v-card class="pa-2 ma-0" style="max-height:100vh;min-height:10vh;">
                        <v-row no-gutters>
                            <v-col>
                                <v-row class="pa-0 ma-0 pl-2 mb">
                                    <div>
                                        <p class=" grey--text">Name:</p>
                                        <p>{{ formatName(Album.Name) }}</p>
                                    </div>
                                </v-row>
                                <v-row class="pa-0 ma-0 pl-2 mb">
                                    <div>
                                        <p class=" grey--text">Year:</p>
                                        <p>{{ Album.Year }}</p>
                                    </div>
                                </v-row>
                                <v-row class="pa-0 ma-0 pl-2 mb">
                                    <div>
                                        <p class=" grey--text">Location:</p>
                                        <p>{{ Album.Location }}</p>
                                    </div>
                                </v-row>

                                <v-row class="pa-0 ma-0 pl-2 mb">
                                    <v-img :src="getImgUrl(Album.Name)" style="display: inline-block; max-height:150px; "/>
                                </v-row>
                                <v-row class="pa-5 ma-0 pl-2 mb" justify="center">
                                    <ViewAlbum :Album="Album"/>
                                </v-row>
                            </v-col>
                        </v-row> 
                    </v-card>
                </v-col>
            </v-row>
    </div>        
</div>
</template>
<style lang="scss" scoped>
.scroll {
    display:block;
    height: 100%;
    width: 100%;
    
    display:fixed;
}
.background{
  max-width: 100%;
  overflow-y: scroll;
  overflow-x:hidden;
  background-image: url(../assets/background.jpg);
  background-size: cover;
}
</style>

<script>
    const axios = require("axios");
    import ViewAlbum from "@/components/ViewAlbum.vue";

    export default {
        components: { ViewAlbum},
        data() {
            return {
                Albums: [],
                dialog: false,
                Name: "",
                titel: "Error in fetching Albums. Try again later...",
                Albumid:"",
                Location:""
            };
        },
        mounted() {
          var postData = {
          };
          let axiosConfig = {
            headers: {
                    // "auth-token": store.state.token,
                },
            };
            const url = `https://bsaoc.be:8443/albums/list`;
            axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                this.Albums = res.data
                // //console.log(res.data)

            })
            .catch((err) => {
                console.log(err);
            });
        }, 
        methods: {
            getImgUrl(folder) {
                return require('../assets/images/' + folder + "/foto1.jpg")
            },
            formatName(name) {
                //replace 2 with space 2
                //capitalize first letter
                name = name.replace("2", " 2");
                name = name.charAt(0).toUpperCase() + name.slice(1);
                return name

            }
        },


    };
</script>
