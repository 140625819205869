<template>
  <nav>
    <!-- <v-toolbar class="primary hidden-lg-and-up justify-center">
      
      <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <v-img
          class="shrink mr-2 hidden-lg-and-up"
          contain
          src="../assets/logo.png"
          transition="scale-transition"
          width="80"
          />
        </div>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-toolbar> -->
    <v-toolbar  class="hidden-md-and-down justify-center" color="#e3e9e5" height="120">
      <v-col cols="1">
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="2" span="" class="d-flex justify-left hidden-md-and-down">

        <div class="d-flex align-center">
          <v-img
          class="shrink mr-2 hidden-md-and-down"
          contain
          src="../assets/logo.png"
          transition="scale-transition"
          width="160"
          />
        </div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-col>

      <v-col cols="7">
        <h1 class="test">
          BSA OWNERS CLUB BELGIUM
        </h1>
      </v-col>
      <v-col cols="1">
        <v-menu
          rounded="lg"
          class="mx-15"

        >
          <!-- <template v-slot:activator="{ attrs, on }">
            <v-btn
              color="#323232"
              class="white--text ma-5"
              v-bind="attrs"
              v-on="on"
            >

              <v-img
                class="shrink mr-2 hidden-md-and-down"
                contain
                :src="getImgUrl3('frans.png')"
                transition="scale-transition"
                width="35"
                />
                <v-icon>expand_more</v-icon>
            </v-btn>
          </template> -->
  
          <!-- <v-list color="#323232">
            <v-list-item
              v-for="([item,icon]) in items"
              :key="item"
              link
            >


              <div class="d-flex align-center">
                <v-img
                class="shrink mr-2 hidden-md-and-down"
                contain
                :src="getImgUrl3(icon)"
                transition="scale-transition"
                width="35"
                />
              </div>
            </v-list-item>
          </v-list> -->
        </v-menu>
      </v-col>
      <v-col cols="1">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-col>
    </v-toolbar>
 
  </nav>
</template>
<style lang="scss" scoped>
@import url(https://fonts.googleapis.com/css?family=Poppins);
.test {
   font-family:Poppins;
   font-size: 3.25rem;
   color:#d9d214;
 }

</style>
<script>

  export default {
    data: () => ({
      items: [["","engels.png"],["","frans.png"],["","nederlands.png"]],
      selected:"",
    }),
    methods: {
      getImgUrl2(image) {
        //console.log(image)
        return require('../assets/' + image )
      },
      getImgUrl3(image) {
        //console.log(image)
        return require('../assets/' + image )
      },
    },
  };
</script>