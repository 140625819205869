<template>
  <nav>
    <v-toolbar class="primary hidden-lg-and-up justify-center" >
      <v-app-bar-nav-icon @click="drawer = !drawer" large class="ml-1"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <v-img
          class="shrink mr-2 hidden-lg-and-up"
          contain
          src="../assets/logo.png"
          transition="scale-transition"
          width="80"
          />
        </div>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-toolbar  class="hidden-md-and-down primary lighten-1  justify-center">
      <v-col cols="1">
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="10" class="d-flex justify-left hidden-md-and-down">
        <!-- <div class="d-flex"> -->
          <v-list-item v-for="link in links" :key="link.text" router :to="link.route" class="lighten-1 primary--text mx-1 shrink"
          active-class="secondary lighten primary--text"
          multiple
          >
           <v-list-item-icon class="mx-1">
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title large>{{ link.text }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        <!-- </div> -->
      </v-col>   
      <v-col cols="1">
        <v-spacer></v-spacer>
      </v-col>   
    </v-toolbar>

    <v-navigation-drawer v-model="drawer" app class="primary hidden-lg-and-up" stateless>
      <v-list dense>
        <v-list-item-group
          class="black--text"
          active-class="secondary darken-1"
          multiple>
          <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title large>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- add a link to https://midflandersbsaoc.wixsite.com/bsa-oc-be-mid-flande with a questionmark as icon -->
          <v-list-item onclick="window.open('https://midflandersbsaoc.wixsite.com/bsa-oc-be-mid-flande', '_blank');">
            <v-list-item-icon>
              <v-icon>help</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title large>John Bull 2024</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
 
  </nav>
</template>

<script>
// import store from "../store/index";
// import store from '../store';
export default {
  data() {
    return {
      drawer: false,
      // username: store.state.naam,
      links: [
        { icon: "home", text: "Home", route: "/" },
        { icon: "image", text: "Photos", route: "/Photos" },
        // { icon: "build", text: "Techcorner", route: "/Techcorner" },
        { icon: "help", text: "Info", route: "/TermsAndCond" },
        { icon: "event", text: "Events", route: "/Events" },
        { icon: "link", text: "Links", route: "/Links" },
        { icon: "contact_support", text: "Contact", route: "/Contact" },
        { icon: "list", text: "Register", route: "/Register" },
        // { icon: "app_registration", text: "IR Register", route: "/IRregister" },
        //{ icon: "admin_panel_settings", text: "Beheerders", route: "/Admin" },
      ],
    };
  },
  // mounted(){
  //   // if(store.getters.getIRToken){
  //   //   // this.links.push({icon:"dashboard",text:"International 2022", route:"/IRDashboard"})
  //   //   // this.links.push({icon:"logout",text:"Log out", route:"/logout"})
  //   // }
  //   // else{
  //   //  this.links.push( { icon: "login", text: "Login", route: "/Login" })
  //   // }
  // },
  // methods: {
  //   onResize () {
  //     let windowsize = { x: window.innerWidth, y: window.innerHeight }
  //     this.drawer = false;
  //     //console.log(windowsize)
  //     //console.log(this.drawer)
  //   },
  //   logout: function () {
  //     store.state.token = null
  //     this.$router.push("/")
  // //   },
  // },
};
</script>

<style scoped>
.atest {
  font-family: Roboto;
}
</style>