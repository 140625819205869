<template>
<div>
  <div class="vue-horizontal-timeline hidden-md-and-down" :style="setTimelineStyles">
    <eventextrainfo :visible="ir2023" @close="ir2023=false"/>
    <johnbullextrainfo :visible="johnbull" @close="johnbull=false"/>
    <TimelineInfo :visible="timeline" @close="timeline=false"/>
    <div class="text-center ma-2">
      <v-snackbar
       color="primary secondary--text"
        v-model="snackbar"
      >
        {{ text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="secondary"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-row>
      <v-col cols = "1">      
        <v-btn
          class="mx-7"
          ref="button"
          color="primary black--text"
          @click="Gotobegin"
        > Goto Begin</v-btn>
      </v-col>
      <v-col cols = "4"></v-col>
      <v-col cols = "2"><v-banner>Drag the timeline to explore!</v-banner></v-col>
      <v-col cols = "4"></v-col>
      <v-col cols = "1">
        <v-btn
          ref="button"
          color="primary black--text"
          @click="Gotonow"
        > Goto Now</v-btn>
      </v-col>
    </v-row>
    <section class="timeline" v-dragscroll.x>
        <ol>
        <li
          v-for="(item, i) in items"
          :key="i"
          :style="setLineColor"
          :class="{'add-step': $scopedSlots.default || item[titleAttr] || item[contentAttr]}"
        >
          <div
            v-if="$scopedSlots.default || item[titleAttr] || item[contentAttr]"
            class="time"
            :class="getTimeClass(item)"
            :style="getTimeStyles" @click="cardClicked(item)"
          >
            <slot v-if="$scopedSlots.default" v-bind:item="item"/>
            <span
              class="title"
              v-if="!$scopedSlots.default && item[titleAttr]"
              :class="getTitleClasses">
              {{ item[titleAttr] | textSubstr(titleSubstr) }}
            </span>
            <p
              class="content"
              v-if="!$scopedSlots.default && item[contentAttr]"
              :class="getContentClasses">
              {{ item[contentAttr] | textSubstr(contentSubstr) }}
            </p>
            <v-avatar
              v-if="item.src"
              class="ma-5"
              size="150"
              tile
            >
              <v-img 
               :src="getImgUrl3(item.src)">
              </v-img>
            </v-avatar>
          </div>
        </li>
        <li :style="setLineColor"></li>
      </ol>
    </section>

  </div>
  <div class="vue-horizontal-timeline hidden-lg-and-up" :style="setTimelineStyles" >
    
    <v-alert
    color="primary secondary--text"
    type="info"
    >Only available on desktop or in desktop mode!</v-alert>

  </div>
</div>
</template>
<script>
import { dragscroll } from 'vue-dragscroll'
import eventextrainfo from "@/components/eventextrainfo.vue";
import TimelineInfo from "@/components/TimelineInfo.vue";
import johnbullextrainfo from '@/components/johnbullextrainfo.vue';
    export default {
    components: { eventextrainfo,TimelineInfo,johnbullextrainfo},
      mounted(){          
        let content = document.querySelector(".timeline");
        content.scrollLeft = content.scrollWidth ;
      },
    directives: {
      dragscroll
    },
    data(){
      return{
        event:"",
        ir2023: false,
        timeline: false,
        snackbar: false,
        johnbull: false,
        text: `More info soon!`,
      }
    },
    name: 'VueHorizontalTimeline',
    props: {
        items: {
        type: Array,
        required: true
        },
        itemSelected: {
        type: Object,
        default: () => ({})
        },
        itemUniqueKey: {
        type: String,
        default: ''
        },
        titleAttr: {
        type: String,
        default: 'title'
        },
        titleCentered: {
        type: Boolean,
        default: false
        },
        titleClass: {
        type: String,
        default: ''
        },
        titleSubstr: {
        type: Number,
        default: 30
        },
        contentAttr: {
        type: String,
        default: 'content'
        },
        contentCentered: {
        type: Boolean,
        default: false
        },
        contentClass: {
        type: String,
        default: ''
        },
        contentSubstr: {
        type: Number,
        default: 250
        },
        minWidth: {
        type: String,
        default: '200px'
        },
        minHeight: {
        type: String,
        default: ''
        },
        timelinePadding: {
        type: String,
        default: ''
        },
        timelineBackground: {
        type: String,
        default: ''
        },
        lineColor: {
        type: String,
        default: '#377737'
        },
        clickable: {
        type: [String, Boolean],
        default: true
        }
    },
    filters: {
        textSubstr (value, qtd = 250, mask = '...') {
        return value && value.length > qtd ? `${value.substring(0, qtd)}${mask}` : value
        }
    },
    computed: {
        setTimelineStyles () {
        const { timelineBackground, timelinePadding } = this
        const styleObj = {}

        if (timelinePadding) {
            styleObj.padding = timelinePadding
        }

        if (timelineBackground) {
            styleObj.background = timelineBackground
        }

        return styleObj
        },
        setLineColor () {
        const { lineColor } = this

        return lineColor ? `background: ${lineColor}` : ''
        },
        getTimeStyles () {
        const { minWidth, minHeight, clickable } = this
        const styleObj = {
            minWidth,
            minHeight
        }

        if (!clickable) {
            styleObj.cursor = 'default'
        }

        return styleObj
        },
        getTitleClasses () {
        const { titleClass, titleCentered } = this

        return titleClass || { 'text-center': titleCentered }
        },
        getContentClasses () {
        const { contentClass, contentCentered } = this

        return contentClass || { 'text-center': contentCentered }
        }
    },
    methods: {
        getImgUrl3(image) {
          return require('../assets/images/Timeline/' + image )
        },
        Gotonow(){
          //console.log("goto now")
          let content = document.querySelector(".timeline");
          content.scrollLeft = content.scrollWidth ;
        },
        Gotobegin(){
          //console.log("goto now")
          let content = document.querySelector(".timeline");
          content.scrollLeft = 0 ;
        },
        cardClicked (item) {
          if (!this.clickable) {
              return
          }
          //console.log("Clicked:")
          //console.log(item.event)
          // if(item.event == "ir2023"){
          //  this.ir2023 = true
          // }else if(item.event == "IR 2023"){
          //   this.snackbar = true
          // }
          if(item.event == "jb2025"){
            // goto https://docs.google.com/forms/d/e/1FAIpQLSfF-qcGA3PGZ1PZIR8wUyo5uJz2puxLTKXFAt4Fhtvj4y3OxA/viewform?usp=dialog in a new tab
            window.open("https://docs.google.com/forms/d/e/1FAIpQLSfF-qcGA3PGZ1PZIR8wUyo5uJz2puxLTKXFAt4Fhtvj4y3OxA/viewform?usp=dialog", "_blank");
          } 
          else if(item.event == "timeline"){
           this.timeline = true
          }
          else{
            this.snackbar = false
          }
          this.$emit('update:itemSelected', { ...item })
          this.$emit('click', item)
        },
        getTimeClass (item) {
        const { itemUniqueKey, itemSelected } = this

        if (itemUniqueKey && itemSelected) {
            return {
            'border-blue': item[itemUniqueKey] === itemSelected[itemUniqueKey]
            }
        }

        return {}
        }
    }
    }

</script>

<style src="../assets/css/style.min.css" scoped></style>
